<ng-container [ngSwitch]="searchType">
  <ng-container *ngSwitchCase="'VENDOR'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Vendor / Actions"
        icon="fast-foward">
        <a routerLink="/vendors/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Vendor
            </span>
          </li>
        </a>
        <li class="dropdown-item"
            (click)="getVendorsToDownload()">
          <div class="dropdown-item-title">
            {{ isExcelDataLoading ? "Downloading..." : "Download Excel" }}
          </div>
        </li>
        <mi-excel-button [dropdownItem]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'CONTACT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Contact / Actions"
        icon="fast-foward">
        <a routerLink="/contacts/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Contact
            </span>
          </li>
        </a>
        <mi-excel-button [dropdownItem]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'TENANT_USER'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Employee / Actions"
        icon="fast-foward">
        <a (click)="openAddUserModal()">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Employee
            </span>
          </li>
        </a>
        <!-- <a (click)="openShowAllFilter()">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Association
            </span>
          </li>
        </a> -->
      </mi-dropdown>
    </span>
  </ng-container>

  <modal #addUserModal class="add-user no-footer-modal user-modal" (closed)="close()">
    <modal-header>
        <h4>Add Employees</h4>
    </modal-header>
    <modal-content>
      <mi-user-management-form
      [editId]="userId"
      (canceled)="close()"
      (userUpdated)="onUserUpdated($event)"
      ></mi-user-management-form>
    </modal-content>
    <modal-footer>
    </modal-footer>
  </modal>

  <ng-container *ngSwitchCase="'ADDRESS'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Address / Actions"
        icon="fast-foward">
        <a routerLink="/addresses/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Address
            </span>
          </li>
        </a>
        <li class="dropdown-item"
            (click)="getTenantAddressesToDownload()">
          <div class="dropdown-item-title">
            {{ isExcelDataLoading ? "Downloading..." : "Download Excel" }}
          </div>
        </li>
        <mi-excel-button [dropdownItem]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'SERVICE_GROUPS'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Service Group / Actions"
        icon="fast-foward">
        <a routerLink="/groups/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Service Group
            </span>
          </li>
        </a>
        <mi-excel-button [dropdownItem]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'EQUIPMENT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Equipment / Actions"
        icon="fast-foward">
        <a [routerLink]="addNewURL? addNewURL : '/equipment/create'">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Equipment
            </span>
          </li>
        </a>
        <mi-excel-button [dropdownItem]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'PRODUCT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Product / Actions"
        icon="fast-foward">
        <a routerLink="./create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Product
            </span>
          </li>
        </a>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'LOCATIONS'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown [customList]="true" [startOpen]="false" dropdownTitle="Add Location / Actions" icon="fast-foward">
        <a routerLink="../location-create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Location
            </span>
          </li>
        </a>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'ACCOUNT_ID'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Account ID / Actions"
        icon="fast-foward">
        <a routerLink="./create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Account ID
            </span>
          </li>
        </a>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'DOCUMENT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Document / Actions"
        icon="fast-foward">
        <ng-content>
        </ng-content>
      </mi-dropdown>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'SERVICE' || 'EVENT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Service / Actions"
        icon="fast-foward">
        <a routerLink="/services/single/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Service
            </span>
          </li>
        </a>
        <li class="dropdown-item"
          (click)="bulkChangeComponent.getDownloadStatus()">
          <div class="dropdown-item-title">
            <mi-excel-with-filter-button #bulkChangeComponent 
              buttonLabel="Bulk Change" 
              [externalTrigger]="true">
            </mi-excel-with-filter-button>
          </div>
        </li>
        <li class="dropdown-item"
            (click)="openRunModal('SERVICE_REPORT')">
          <div class="dropdown-item-title">
            Reports / Views
          </div>
        </li>
        <mi-excel-button [dropdownItem]="true" [printButton]="true"></mi-excel-button>
      </mi-dropdown>
    </span>
  </ng-container>
</ng-container> 