import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { Router } from "@angular/router";
import { DocumentV2Service } from 'src/libs/api2/document-v2/document-v2.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { map, takeUntil } from 'rxjs/operators';
import { Link } from '../../../../shared/ng-models/Link.interface';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { CurrencyService } from 'src/libs/api2/currency-v2/currency.service';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { DocumentCmrcComponent } from '../document-cmrc/document-cmrc.component';
import { DocumentIconComponent } from '../document-Icon/document-Icon.component';


@Component({
  selector: 'document-view',
  templateUrl: './documents-manager.component.html',
  styleUrls: ['./documents-manager.component.scss']
})

export class DocumentManagerComponent extends AbstractPageComponent implements OnInit {

  breadCrumbs = [
    {
      label: 'Dashboard',
      url: '/',
      internal: true
    },
    {
      label: 'Documents Manager',
      url: '/documents-manager',
      internal: true
    },
  ];

  headerLinks: Link[];
  isSearchEmptySVC: boolean = true;
  isSearchEmptyServiceIdentifier: boolean = true;
  isDataLoaded: boolean = false
  filteredDataRowCounts: any
  appliedFilteredCounts: any
  currencyDetails: any
  svcSearchParam: Subject<string> = new Subject<string>();
  svcInputParam: string;
  serviceIdentifierSearchParam: Subject<string> = new Subject<string>();
  searchByServiceDisplayId: string = ''
  searchByServiceIdentifier: string = ''
  source: LocalDataSource = new LocalDataSource();
  documents: any;
  formattedData: any[] = [];
  pageSize: number = 50
  pageNumber: number = 0
  vendors: any[] = []
  isMoreDocumentsLoading: boolean = false
  totalNumberOfDocuments: number
  tenantData: any = null;
  @ViewChild('searchInputSVC') searchInputSVC: ElementRef;
  @ViewChild('searchInputServiceIdentifier') searchInputServiceIdentifier: ElementRef;
  settings = {
    pager: {
      display: true,
      perPage: 10,
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
    },
    noDataMessage: "Loading...",
    rowClassFunction: (row) => 'cursor-pointer',
    columns: {}
  }

  constructor(
    public router: Router,
    public documentV2Service: DocumentV2Service,
    private currencyService: CurrencyService,
    private tenantV2Service: TenantV2Service,
    private store: Store<any>,
    private decimalPipe: DecimalPipe
  ) {
    super();

    this.svcSearchParam.pipe(debounceTime(500))
      .subscribe(searchParam => {
        this.svcInputParam = searchParam
        if (searchParam.length > 0) {
          this.searchByServiceDisplayId = 'SVC-' + searchParam
        } else {
          this.searchByServiceDisplayId = ''
        }

        this.getTenantDocuments()
      });

    this.serviceIdentifierSearchParam.pipe(debounceTime(500))
      .subscribe(searchParam => {
        this.searchByServiceIdentifier = searchParam
        this.getTenantDocuments()
      });

  }

  ngOnInit(): void {

    this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.tenantData = appData.tenantData;
        console.log("here tenantData", this.tenantData)
        // this.businessUnitId = appData.tenantData.domain;
        // this.getBusinessUnit(appData.tenantData.domain);
        this.getBusinessDetails()
      })
    ).subscribe();
  }



  getBusinessDetails() {
    this.tenantV2Service
      .getBusinessUnit(this.tenantData.domain)
      .toPromise()
      .then(response => {
        console.log("here getBusinessDetails response", response)
        this.getCurrencyDetailsDetails(response)
      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }

  getCurrencyDetailsDetails(businessDetails) {
    this.currencyService
      .getCurrencyDetailsByCurrencyId(businessDetails.tenant_currency_id)
      .toPromise()
      .then(response => {
        this.currencyDetails = response
        this.isDataLoaded = true
        this.getTenantDocuments()
      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }


  getTenantDocuments() {
    this.documentV2Service
      .getTenantDocumentsService(this.currencyDetails.tenant_id, this.pageNumber, this.pageSize, this.searchByServiceDisplayId, this.searchByServiceIdentifier)
      .toPromise()
      .then(response => {
        this.totalNumberOfDocuments = response.total

        if (response.moreAvailable) {
          this.isMoreDocumentsLoading = true
          this.pageNumber = 0
          this.pageSize = response.total
          this.getTenantDocuments()
        } else {
          this.isMoreDocumentsLoading = false
        }

        this.filteredDataRowCounts = !response.moreAvailable ? response.data.length : response.total

        this.formattedData = response.data.map((document) => {
          return {
            ...document,
            currencyDetails: this.currencyDetails,
            doc_id: parseInt(document.display_id.split('-')[1]),
            doc_extraction_date_utc: document.extraction_date != null ? this.getFormattedDate(document.extraction_date) : null
          }
        })

        this.source = new LocalDataSource(this.formattedData);

        this.initializeTable()

      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }


  getFormattedDate(extraction_date) {
    let [month, day, year] = extraction_date.split('/');
    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;

  }


  initializeTable() {

    const vendors = this.formattedData.map((document: any) => {
      return {
        value: document.vendor_name,
        title: document.vendor_name
      }
    })

    const allVendors: any[] = Object.values(vendors.reduce((acc, obj) => {
      if (obj.hasOwnProperty('value') && obj.value !== undefined && obj.value !== '' && /[A-Z]/.test(obj.value)) {
        acc[obj.value] = obj;
      }
      return acc;
    }, {})
    );

    this.vendors = allVendors.slice().sort((a, b) => {
      if (a.value.toUpperCase() < b.value.toUpperCase()) {
        return -1;
      }
      if (a.value.toUpperCase() > b.value.toUpperCase()) {
        return 1;
      }
      return 0;
    });


    console.log("here this.vendors", this.vendors)
    
    this.isDataLoaded = true
    this.settings = {
      pager: {
        display: true,
        perPage: 10
      },
      actions: {
        add: false,
        delete: false,
        edit: false,
        position: 'right',
      },
      noDataMessage: "No Documents found",
      rowClassFunction: (row) => 'cursor-pointer',
      columns: {
        doc_id: {
          title: 'DOC-ID',
          editable: false,
          sortDirection: 'desc',
          type: 'html',
          filter: true,
          width: '9%',
          valuePrepareFunction: (cell: any, row: any) => 'DOC-' + row.doc_id
        },
        vendor_name: {
          title: 'Vendor',
          editable: false,
          filter: {
            type: 'list',
            config: {
              selectText: this.vendors.length > 0 ? 'All Vendors' : 'No Vendors',
              list: this.vendors,
            },
          },
          width: '15%',
        },
        account_number: {
          title: 'Account ID',
          editable: false,
          filter: true,
          width: '15%',
          type: 'html',
          valuePrepareFunction: (cell: any, row: any) => row.account_number ? row.account_number : '-'
        },
        invoice_number: {
          title: 'Invoice #',
          editable: false,
          width: '12%',
          type: 'html',
          filter: true,
          valuePrepareFunction: (cell: any, row: any) => row.invoice_number ? row.invoice_number : '-'
        },
        doc_extraction_date_utc: {
          title: 'Invoice Date',
          editable: false,
          filter: false,
          width: '11%',
          type: 'html',
          valuePrepareFunction: (cell: any, row: any) => row.extraction_date != null ? row.extraction_date : '-'
        },
        invoice_value: {
          title: 'Invoice Value',
          editable: false,
          width: '12%',
          type: 'html',
          filter: false,
          valuePrepareFunction: (cell: any, row: any) => row.invoice_value ? this.decimalPipe.transform(row.invoice_value, '1.0-2') : '-'
        },
        invoice_tenant_currency_code: {
          title: 'Currency',
          editable: false,
          filter: false,
          width: '8%'
        },
        service_count: {
          title: 'SVC-Count',
          editable: false,
          filter: false,
          width: '12%'
        },
        mrc: {
          title: 'CMRC',
          type: 'custom',
          renderComponent: DocumentCmrcComponent,
          filter: false,
        },
        documentIconComponent: {
          title: 'View',
          editable: false,
          filter: false,
          class: 'disable-sorting',
          sortable: false,
          type: 'custom',
          renderComponent: DocumentIconComponent,
          width: '2%',
        },
      }
    };
  }

  onRowSelect(event: any) {
    this.documentV2Service
      .getBulkUploadSourceFileUrlService(event.data.id)
      .toPromise()
      .then(response => {
        window.open(response.download_url, "_blank");
      })
      .catch((e) => console.warn(e));
  }

  onChange() {
    setTimeout(() => {
      const getFilter = this.source.getFilter();
      this.appliedFilteredCounts = getFilter.filters.filter(filter => { if (filter.search.length > 0) { return filter } }).length
      const rowsCount = this.source.count();
      this.filteredDataRowCounts = rowsCount
    }, 1000)
  }

  onSearchChange(searchValue): void {
    this.isDataLoaded = false
    this.filteredDataRowCounts = null
    this.pageSize = 50
    this.isSearchEmptySVC = searchValue.trim() === '';
    this.svcSearchParam.next(searchValue.replace(/^\s+|\s+$/gm, '') || '');
  }
  onClearSearchSVC() {
    this.isSearchEmptySVC = true;
    this.searchInputSVC.nativeElement.value = '';
    this.onSearchChange('');
  }

  onClearSearchServiceIdentifier() {
    this.isSearchEmptyServiceIdentifier = true;
    this.searchInputServiceIdentifier.nativeElement.value = '';
    this.searchByServiceIdentifierHandler('');
  }

  searchByServiceIdentifierHandler(searchValue): void {
    this.isDataLoaded = false
    this.filteredDataRowCounts = null
    this.pageNumber = 0
    this.pageSize = 50
    this.isSearchEmptyServiceIdentifier = searchValue.trim() === '';
    // this.serviceIdentifierSearchParam.next(searchValue.replace(/^\s+|\s+$/gm, '') || '');
    this.serviceIdentifierSearchParam.next(searchValue);
  }


  getMessage(): string {
    let message: string = '';

    switch (true) {
      case this.filteredDataRowCounts === 1:
        message = `Total ${this.filteredDataRowCounts} Document found for service ${this.searchByServiceDisplayId} ${this.appliedFilteredCounts > 0 ? "with respect to applied filters" : ""}`;
        break;
      case !this.searchByServiceDisplayId && this.filteredDataRowCounts > 0 && this.isMoreDocumentsLoading:
        message = `Total ${this.getFormattedDataLength()} documents to show and ${this.totalNumberOfDocuments - this.getFormattedDataLength()} are loading...`;
        break;
      case this.searchByServiceDisplayId && this.filteredDataRowCounts > 0:
        message = `Total ${this.filteredDataRowCounts} Documents found for service ${this.searchByServiceDisplayId} ${this.appliedFilteredCounts > 0 ? "with respect to applied filters" : ""}`;
        break;
      case !this.searchByServiceDisplayId && this.filteredDataRowCounts > 0 && !this.isMoreDocumentsLoading:
        message = `Total ${this.filteredDataRowCounts} Documents found ${this.appliedFilteredCounts > 0 ? "with respect to applied filters" : ""}`;
        break;
      case this.filteredDataRowCounts === 0:
        message = `No Document found ${this.searchByServiceDisplayId.length > 0 ? "for service id " + this.searchByServiceDisplayId : ""} ${this.appliedFilteredCounts > 0 ? "with respect to applied filters" : ""}`;
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  getFormattedDataLength(): number {
    return this.formattedData.length;
  }

}