import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import { AbstractPageComponent } from '../../../pages/abstract-page/abstract-page.component';
import { ModalService } from '@independer/ng-modal';
import { CreateReportOrViewModalComponent } from './create-report-or-view-modal/create-report-or-view-modal.component';
import { RunReportOrViewModalComponent } from './run-report-or-view-modal/run-report-or-view-modal.component';
import { ExcelEmailNotificationModalComponent } from '../../excel-email-notification-modal/excel-email-notification-modal.component';
import { AdvancedSearchFilterModalComponent } from '../../advanced-search-filter-modal/advanced-search-filter-modal.component';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';
import { UsersManagementFormComponent } from '../../users-management-form/users-management-form.component';
import * as appDataReducer from "../../../state/app-data/reducers";
import { Tenant, TenantVendor } from 'src/shared/models/Tenant';
import { AddressV2Service } from 'src/libs/api2/address-v2/address-v2.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';

@Component({
  selector: 'mi-header-action-dropdown',
  templateUrl: './action-dropdown-buttons.component.html',
  styleUrls: ['./action-dropdown-buttons.component.scss']
})

export class HeaderActionDropdownComponent extends AbstractPageComponent {

  @Input() searchType: string = 'SERVICE';
  @Input() addNewURL: string = '';
  @ViewChild('addUserModal') addUserModal;
  userId: string = '';
  @ViewChild(UsersManagementFormComponent) usersManagementFormComponent!: UsersManagementFormComponent;
  @Output() userCreated = new EventEmitter<TenantUser>();


  isExcelDataLoading: boolean = false;
  addressToDownloadExcel: any[] = [];
  error: string;
  tenantData: Tenant;
  getTenantAddressesToDownloadPageNumber: number = 0;
  getVendorListToDownloadPageNumber: number = 0;
  allDownloadedAddress: any[] = [];
  allDownloadedVendors: any[] = [];
  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';

  searchString: string = '';
  sortByColumn: string
  selectedColumnOrder: string
  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  constructor(
    private modalService: ModalService,
    private tenantAddressV2Service: AddressV2Service,
    private tenantVendorService: TenantVendors,
    private toastrService: ToastrService,
    private store: Store<ASReducer.State>) {
    super();
  }

  openAddUserModal() {
    this.addUserModal.open();
  }

  onUserUpdated(user: TenantUser): void {
    this.userCreated.emit(user);
    this.addUserModal.close();
  }

  close() {
    this.addUserModal.close();
    this.usersManagementFormComponent.resetForm();
  }

  onSearchSubmit(searchValue) {

    const newQuery = {
      q: searchValue
    };

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  clearSearch() {
    const newQuery = {
      q: ''
    };
    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  ngOnInit() {
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe(
      (data) => this.tenantData = data.tenantData,
      (e) => (this.error = e)
    );
  }

  openRunModal(action_type: string = "SERVICE_REPORT") {

    const modalRef = this.modalService.open(RunReportOrViewModalComponent, m => {
      m.action_type = action_type;
      m.searchType = this.searchType;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;
      console.log("report", report)
      if (report && report.print && action_type === "SERVICE_REPORT") {
        this.createNotification("SERVICE_REPORT");
      }

    });
  }

  emailNotification() {
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m => {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });
  }

  createNotification(action_type) {
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m => {
      m.title = "Create Notification";
      m.message = `Your request to create ${action_type} was successful. Please check Actions to use it in the future.`;
      m.closeButtonLabel = "Confirm";
    });
  }

  openCreateModal(action_type: string = "SERVICE_REPORT") {
    const modalRef = this.modalService.open(CreateReportOrViewModalComponent, m => {
      m.action_type = action_type;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;

      if (report && report.report) {
        this.createNotification(action_type);
      }

    });
  }

  openShowAllFilter() {
    const modalRef = this.modalService.open(AdvancedSearchFilterModalComponent, m => {
      m.searchType = ASReducer.ADVANCED_SEARCH_TYPES.TENANT_USER;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results

      const [data] = result;
      if (data) {
        console.log(data);
      }

    });
  }

  filterObjectArray(data: any[]): any[] {
    // Create a Map to store unique entries based on `id`
    const uniqueDataMap = new Map();

    data.forEach(({ id, street_address, state_id, country_id, google_place_id, owner, locations, ...filtered }) => {
      if (!uniqueDataMap.has(id)) {
        uniqueDataMap.set(id, filtered);
      }
    });

    // Return the unique values as an array
    return Array.from(uniqueDataMap.values());
  }

  filterVendorObjectArray(data: any[]): any[] {
    const uniqueMap = new Map();

    data.forEach(({ id, ...rest }) => {
      if (!uniqueMap.has(id)) {
        uniqueMap.set(id, rest);
      }
    });

    return Array.from(uniqueMap.values());
  }

  getTenantAddressesToDownload() {
    if (this.getTenantAddressesToDownloadPageNumber === 0) {
      this.toastrService.info(
        `Please wait we are generating Addresses excel.`,
        '',
        { timeOut: 8000 } // Set the duration to 8 seconds
      );
    }

    this.isExcelDataLoading = true;
    this.tenantAddressV2Service
      .getAllAddressByOwner(this.tenantData.domain, '', this.getTenantAddressesToDownloadPageNumber, 200, "cmrc")
      .toPromise()
      .then(data => {
        console.log("here getTenantAddressesToDownload data", data);
        this.allDownloadedAddress.push(...data.data);
        if (data.moreAvailable) {
          console.log("here getTenantAddressesToDownload this.allDownloadedAddress", this.allDownloadedAddress);
          this.getTenantAddressesToDownloadPageNumber = this.getTenantAddressesToDownloadPageNumber + 1;
          this.getTenantAddressesToDownload();
        } else {
          console.log("here getTenantAddressesToDownload this.allDownloadedAddress before modify", this.allDownloadedAddress);
          const modifiedData = this.filterObjectArray(this.allDownloadedAddress);
          console.log("here getTenantAddressesToDownload modifiedData", modifiedData);
          this.isExcelDataLoading = false;
          this.downloadAddressExcel(modifiedData);
        }
      })
      .catch((e) => {
        this.error = e;
        this.isExcelDataLoading = false;
        this.toastrService.success(`Facing some issue while downloading addresses`);
      });
  }

  getVendorsToDownload() {
    if (this.getVendorListToDownloadPageNumber === 0) {
      this.toastrService.info(
        'Please wait we are generating Vendors excel.',
        '',
        { timeOut: 8000 }
      );
    }

    this.isExcelDataLoading = true;

    this.tenantVendorService
      .searchTenantVendorsWithActiveServices(
        this.searchString,
        this.getVendorListToDownloadPageNumber,
        200,
        this.tenantData.domain,
        this.selectedColumnOrder,
        this.sortByColumn,
        true
      )
      .toPromise()
      .then((data) => {
        if (data && data.data) {
          this.allDownloadedVendors.push(...data.data);

          // Check if more data is available or not
          if (data.moreAvailable) {
            this.getVendorListToDownloadPageNumber++;
            this.getVendorsToDownload();
          } else {
            this.isExcelDataLoading = false;
            const uniqueData = this.filterVendorObjectArray(this.allDownloadedVendors);
            this.downloadVendorsExcel(uniqueData);
          }
        } else {
          console.error('here the No data received from the API.');
          this.isExcelDataLoading = false;
        }
      })
      .catch((error) => {
        console.error('here the Error during vendor data fetch:', error);
        this.toastrService.error('Failed to generate the Excel file.');
        this.isExcelDataLoading = false;
      });
  }

  downloadAddressExcel(modifiedData): void {

    const columnHeaders = [
      { header: 'Address', key: 'formatted_address' },
      { header: 'City', key: 'city' },
      { header: 'State', key: 'state_name' },
      { header: 'State Other', key: 'state_other' },
      { header: 'Zip', key: 'zip' },
      { header: 'Country', key: 'country_name' },
      { header: 'Latitude', key: 'latitude' },
      { header: 'Longitude', key: 'longitude' },
      { header: 'Monthly Spend', key: 'c_mrc' },
      { header: 'Location Count', key: 'location_count' },
      { header: 'Service Count', key: 'service_count' },
    ];

    this.generateExcelFile(
      modifiedData,
      columnHeaders,
      'addressdownload',
      'Addresses',
      (row, item) => {
        if (item.c_mrc !== undefined && item.c_mrc !== null) {
          // row['c_mrc'] = new Intl.NumberFormat('en-US', {
          //   minimumFractionDigits: 2,
          //   maximumFractionDigits: 2,
          // }).format(item.c_mrc);
          row['c_mrc'] = item.c_mrc;
        }
        if (item.latitude) row['latitude'] = Number(item.latitude).toFixed(4);
        if (item.longitude) row['longitude'] = Number(item.longitude).toFixed(4);
      }
    );
  }

  downloadVendorsExcel(vendorExcelData: any[]) {

    vendorExcelData.sort((a, b) => {
      const vndId1 = parseInt(a.display_id.replace('VND-', ''), 10);
      const vndId2 = parseInt(b.display_id.replace('VND-', ''), 10);
      return vndId1 - vndId2;
    });

    const columnHeaders = [
      { header: 'VND-ID', key: 'display_id' },
      { header: 'Vendor', key: 'vendor.vendor_name' },
      { header: 'CMRC', key: 'mrc' },
      { header: 'Accounts', key: 'accounts_length' },
      { header: 'Services', key: 'service_count' },
      { header: 'Products', key: 'products_length' },
      { header: 'End of Term Action (Vendor)', key: 'end_of_term_action' }
    ];

    this.generateExcelFile(
      vendorExcelData,
      columnHeaders,
      'Vendorsdownload',
      'Vendors',
      (row, item) => {
        row['end_of_term_action'] = this.formatEndOfTermAction(
          item.service_term_event_type,
          item.auto_renew_length,
          item.days_to_notify
        );
        // row['mrc'] = this.formatVendorExcelMrc(item.mrc);
        row['mrc'] = Math.round(item.mrc);
        row['accounts_length'] = Array.isArray(item.accountid_display_ids) ? item.accountid_display_ids.length : 0;
        row['products_length'] = Array.isArray(item.product_display_ids) ? item.product_display_ids.length : 0;
      }
    );
  }

  // formatVendorExcelMrc(mrc: number): number | string {
  //   if (mrc !== undefined && mrc !== null) {
  //     const roundedValue = Math.round(mrc);
  //     return new Intl.NumberFormat('en-US', {
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     }).format(roundedValue);
  //   }
  //   return 0;
  // }

  formatEndOfTermAction(
    serviceTermEventType: string,
    autoRenewLength: number,
    daysToNotify: number
  ): string {
    if (!serviceTermEventType || !autoRenewLength || daysToNotify === undefined) {
      return '-';
    }

    const termTypeMap = {
      MONTHS_QTY: `${autoRenewLength} Months`,
      YEARS_QTY: `${autoRenewLength} Years`
    };

    const termDescription = termTypeMap['MONTHS_QTY'];
    const notificationText = `${daysToNotify} Day Advance Notification`;

    return `Auto-Renew - Anually ( ${termDescription} ), ${notificationText}`;
  }


  generateExcelFile(
    data: any[],
    columnHeaders: { header: string; key: string }[],
    fileNamePrefix: string,
    sheetName: string,
    additionalRowProcessing: (row: any, item: any) => void
  ) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    // Set column headers
    worksheet.columns = columnHeaders.map(col => ({
      header: col.header,
      key: col.key
    }));

    // Populate data rows
    data.forEach((item) => {
      const row: any = {};
      columnHeaders.forEach((col) => {
        const value = col.key.split('.').reduce((acc, key) => acc && acc[key], item);
        row[col.key] = value !== undefined ? value : '-';
      });

      // Apply additional processing for specific rows
      additionalRowProcessing(row, item);

      worksheet.addRow(row);
    });

    if(fileNamePrefix === 'Vendorsdownload') {
      worksheet.getColumn('mrc').numFmt = '#,##0';
    } else {
      worksheet.getColumn('c_mrc').numFmt = '#,##0.00';
    }

    // Apply header styles
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, size: 11 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9E1F2' }
      };
    });

    // Auto-adjust column widths
    worksheet.columns.forEach((column) => {
      let maxLength = column.header.length;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellLength = cell.value ? cell.value.toString().length : 0;
        maxLength = Math.max(maxLength, cellLength);
      });
      column.width = maxLength + 2;
    });

    // Apply auto filter
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];
    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: 1, column: columnHeaders.length }
    };

    // Generate and download the file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const date = new Date();
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date.getFullYear()}`;
      const fileName = `${fileNamePrefix}_${this.tenantData.v2.domain}_${formattedDate}.xlsx`;
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);
    }).catch((error) => {
      console.error('Error generating Excel file:', error);
      this.toastrService.error('Failed to generate the Excel file.');
    });
  }


}
